.Support{
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
}

.sp-container{
    
    margin-top:8rem;
    display: flex;
    width: 80%;
}

.sp-field{
    width: 80%;
    display: flex;
    padding-left: 5rem;
    
}
.sp-box-title{
    font-weight: 600;
    font-size: 2rem;
}
.sp-title-container{
    width: 20%;
    
}
.sp-title{
    font-size: 1.3rem;
    font-weight: 600;
    cursor: pointer;
    &.selected {
        color: red;
      }
}
.sp-sub-title{
    margin-left: .5rem;
    margin-top: .4rem;
    color: rgb(129, 129, 129);
    cursor: pointer;
}
.sp_section{
    margin-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 2px solid rgb(209, 209, 209);
}
.sp-sub-container{
    margin-top: 1rem;
}

.selected {
    color:#1ba94a;
  }


  .sp-box-container{
    font-size: 1.2rem;
    margin-top: 3rem;
  }
  
  .sup-footer{
    justify-content: center;
    align-items: center;
    display: flex;
    height: 3rem;
    width: 100%;
    background: #1ba94a;
  }
  .sup-container{
    color: #fff;
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 80%;
  }
  .footer-cmpny{
    font-weight: 600;
    
  }

  .bck-btn{
    display: none;
    width: 100%;
    align-items: center;
    justify-content: right;
    font-size: .9rem;
  }
 .sup-container-box{
    display: flex;
    justify-content: center;
    width: 100%;
    
 }

  @media screen and (max-width:1024px){
    .sp-box-title{
        font-size: 1.5rem;
    }
    .sp-container{
        width: 95%;
    }
    .sp-title-container{
        width: 40%;
    }
  }

  @media screen and (max-width:768px) {
    
    .sp-field{
        padding-left: 1rem;
    }
    .sp-box-title{
        font-size: 1rem;
    }
    .sp-title{
        font-size: 1rem;
    }
    .sp-sub-title{
        font-size: .8rem;
    }

    .sp-box-container{
        font-size: .9rem;
        margin-top: 1rem;
    }
  }

  @media screen and (max-width:568px){
    .bck-btn{
        display: flex;
        p{
            color: #1ba94a;
            border-bottom: 1px solid #1ba94a;
        }
    }
    .sp-container{
        margin-top: 4rem;
        flex-direction: column;
    }
    .sp-title-container{
        width: 100%;
    }
    .sp-field{
        width: 100%;
        padding-left: 0;
    }
    .selected-content{
        margin-top: 2rem;
        width: 100%;
    }
    .footer-cmpny{
        display: none;
    }
    .sup-container{
        justify-content: center;
        font-size: .8rem;
    }
  }