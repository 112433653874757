.auth-space{
    display: flex;
    width: 100%;
    height: calc(100vh - 70px);
    justify-content: center;
    align-items: center;
}
.auth-form {
    width: 300px;
    
    padding: 20px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
  }
  
  .tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: #eee;
    cursor: pointer;
    font-size: 16px;
  }
  
  .tab-buttons .active {
    background-color: #00a000;
    color: white;
  }
  .mark-txt{
    margin-left: 1rem;
  }
  
//   form input {
//     width: 100%;
//     padding: 10px;
//     margin: 10px 0;
//     border-radius: 5px;
//     border: 1px solid #ccc;
//   }
.space-reg{
    margin: 2rem;
}
.input-field{
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: .5rem 0;
}
.check-mark{

}

  form button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #00a000;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
.check-mark-field {
    display: flex;
    margin: 10px 0;
    text-align: left;
  }
  
