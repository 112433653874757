.OfferCard {
    overflow: hidden;
    box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.35);
    position: relative;
    margin-bottom: 1rem;
    width: 100%;
    height: 35rem;
    background: #FFF;
    display: -webkit-box; /* Old Safari and iOS */
    display: -moz-box; /* Old Firefox */
    display: -ms-flexbox; /* IE 10 */
    display: -webkit-flex; /* Newer versions of Safari */
    display: flex; /* Standard */
    flex-direction: column;
    align-items: center;
    text-align: center;
    
    // transition: all 0.5s ease-in-out;
    // -webkit-transition: all 0.5s ease-in-out; 
}
.card-offer-list{
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    li{
        align-items: center;
        width: 100%;
        display: flex;
        justify-content:space-between;
    }
}
.card-offer-item{
    margin: .5rem;
    text-align: left;
    width: 100%;
}

.point{
    margin: 1rem;
    min-width: 10px;
    height: 10px;
    background: #000000;
    border-radius: 50%;
}
.offer-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.desc-text-p{
    text-align: right;
}

.about-art-btn {
    user-select: none;
    padding: 1rem; /* corrected */
    font-weight: 700;
    cursor: pointer;
    color: #1ba94a;
    text-decoration: underline;
}

.artwork-desc {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
    justify-content: center;
}

.artwork-desc li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.artwork-title {
    font-size: 1.5rem;
    font-weight: 700;
}

.artworkImg {
    will-change: transform;
    
    width: 100%;
    height: auto; /* corrected */
    // transition: height 0.5s ease-in-out;
    // -webkit-transition: height 0.5s ease-in-out; 
}

.offer-btn {
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid #000000;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.65);
}

.offer-btn p {
    margin: .5rem 2rem;
}

.offer-content {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 85%;
    // transition: all 0.5s ease-in-out;
    // -webkit-transition: all 0.5s ease-in-out;
}

.offer-title {
    
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer-title p {
    margin: 1rem 0;
    font-weight: 700;
    font-size: 1.3rem;
    text-transform: uppercase;
}

.card-img {
    width: 100%;
    height: 90%;
    
    background: #ffffff;
    // transition: all 0.5s ease-in-out;
    // -webkit-transition: all 0.5s ease-in-out; 
}
.db-btn{
    user-select: none;
    cursor: pointer;
    z-index: 7;
    width: 100%;
    background: #1ba94a;
    p{
        margin: .3rem;
        text-transform: uppercase;
        color: #FFF;
        font-weight: 600;
    }
}
.offer-desc {
    p{
        margin-top: 1rem;
    }
    margin: 1rem 0;
    border-top: 1px solid #000000;
    
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    // transition: all 0.5s ease-in-out;
    // -webkit-transition: all 0.5s ease-in-out; 
}

.btn-container {
    z-index: 5;
    width: 80%;
    height: 15%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}



.hidden {
    opacity: 0;
    // transform: translateY(-100%);
    // -webkit-transform: translateY(-100%); 
}
.card-price{
    margin-top: .2rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.artwork_open{
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: 600;
}
.artwork_token{
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    margin-top: 1rem;
    font-weight: 600;
}
.artwork-section {
    z-index: 4;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    height: 92%;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    // transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out;
    // -webkit-transition: opacity 0.5s ease-in-out, top 0.5s ease-in-out; 
}

.spaceCard {
    height: 15%;
}

.artwork-section.visible {
    top: 0;
    opacity: 1;
}

.artworkImg.visible {
    height: 70%;
    // transition: height 0.5s ease-in-out, top 0.5s ease-in-out;
    // -webkit-transition: height 0.5s ease-in-out, top 0.5s ease-in-out; 
}

.artwork-desc {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    min-height: 30%;
    text-align: center;
}

.desc-title {
    font-weight: 600;
    margin: 2px;
}
@media screen and (max-width: 1024px) {

    .OfferCard{
        margin-bottom: 3rem;
        max-width:none;
    }
}
@media screen and (max-width: 768px) {
    .OfferCard{
        height: 30rem;
    }
    .card-offer-item{
        p{
            font-size: .9rem;
        }
        
    }
    .artwork-desc{
        font-size: .9rem;
    }
    .offer-btn p{
        font-size: .9rem;
    }
    .OfferCard{
        margin-bottom: 1.5rem
    }
    .offer-title p{
        font-size: 1rem;
    }
    .offer-desc{
        font-size: .9rem;
    }
    
}
@media screen and (max-width: 568px){
    
}

@media screen and (max-width: 426px) {
    .card-offer-item{
        margin: 0 .2rem;
    }
}

@media screen and (max-width: 361px) {
    
}
