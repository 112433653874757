.Panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem;

    width: 100%;
    box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.35);
    
}

.panel-title{
    width: 80%;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.3rem;
    font-weight: 700;
    
}

.panel-text{
    margin: 1rem;
    width: 90%;
    text-align: center;
    font-size: 1rem;
    p{
        margin-bottom: 1rem;
    }
}

.img-item {
    

    -webkit-background-size: cover;
    background-size: cover;
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-repeat: no-repeat;
    background-repeat: no-repeat;


    width: 50%;
    padding-top: 50%;
    margin-top: 1rem;
    position: relative;
}
@media screen and (max-width: 768px) {
    .panel-title{
        font-size: 1rem;
    }
    .panel-text{
        font-size: .9rem;
    }
    .img-item {
        padding-top: 0;
        width: 75px;
        height: 75px;
    }
}