:root {
    --font-family: 'Montserrat', sans-serif;
    --color-black: #252525;
    --color-white: #fff;
    --color-green: #1ba94a;
    --color-dark-gray: rgba(71, 71, 71, 0.5);
    --color-dark-bg: rgba(23, 23, 23, 0.65);
    --font-size-large: 4rem;
    --font-size-medium: 1.2rem;
    --font-size-small: 1rem;
    --border-radius: 15px;
    --header-height: 100%;
    --footer-height: 40px;
    --min-height-img-space: 60vh;
    --img-space-width: calc(100vw / 3);
}

$secondary-color: #252525;

*,
body,
html {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Regular.woff2') format('woff2'),
        url('/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

ul {
    list-style-type: none;
    text-decoration: none;
}

a {
    text-decoration: none;
    color: var(--color-black);
}

.App {}


header {
    min-height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
