.infographic {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 2rem 0;
  }
  
  .text-field{
    width: 45%;
    font-size: 1.2rem;
  }

  .info-field{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    width: 50%;
    background-color: #ffffff;
    box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.35);
  }
  .infographic-section {
    margin-bottom: 20px;
    
  }
  
  .infographic-section h2 {
    font-size: 24px;
    color: #4f4f4f;
    text-align: center;
    margin-top: 1rem;
  }
  
  .infographic-item {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .infographic-item img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .infographic-item p {
    font-size: 16px;
    color: #333;
  }
  
  


  @media screen and (max-width: 1024px){
    .text-field{
        font-size: 1rem;
    }
  }

  @media screen and (max-width: 768px){
    .infographic-section h2{
        font-size: 1.2rem;
    }
    .infographic{
        margin-top: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .info-field{
        width: 90%;
    }
    .text-field{
        margin-top: 2rem;
        width: 90%;
        .imp-buf{
            font-size: .8rem;
        }
    }
    .imp-title{
        margin-bottom: 1rem;
    }
  }