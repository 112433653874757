.Footer{
    width: 100%;
    height: 70px;
    background: rgb(53, 53, 53);
    // position: absolute;
    // bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-container{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.company-segment{
    display: flex;
    align-items: center;
}

.company-title{
    text-align: center;
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    padding-bottom: 0.1rem;
    letter-spacing: .4rem;
    border-bottom: 1px solid white;
}
.company-sub-title{
    padding-top: .2rem;
    font-size: .8rem;
    text-align: centersta;
    color: rgb(255, 187, 0);
}
.company-logo{
    
    margin: 0 1rem;
    width: 30px;
    height: 30px;
}

.footer-info{
    ul {
        @media (max-width: 768px) {
            display: none;
        }
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        li{
            margin:0 1rem ;
            a{
                color: white;
            }
        }
      }
}

@media screen and (max-width: 768px) {
    .company-title{
        font-size: .8rem;
    }
    .company-sub-title{
        font-size: .7rem;
    }
    .company-logo{
        width: 50px;
        height: 50px;
        margin: 0 .5rem;
    }
}