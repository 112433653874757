.NavBar {
    // padding-bottom: 4.7rem;
  }
  
  .nav-bar-buf {
    box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  
  .nav-container {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-container:hover {}
  
  .nav-upper {
    margin: .3rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    
    text-decoration: none;
  }
  
  .link-style {
    text-decoration: none;
  }
  
  .nav-stroke {
    width: 100%;
  
    background: #1ba94a;
    align-items: center;
    justify-content: center;
    display: flex;
  
    .nav-link-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .nav-point {
      margin: .3rem 2rem; // Расстояние между ссылками
    }
  
  
  }
  
  .nv-link {
  
    text-decoration: none; // Убираем декорацию ссылок
    text-transform: uppercase;
    font-weight: 200;
  
    &.type1 {
      color: #000;
    }
  
    &.type2 {
      color: #ffffff;
  
    }
  
  }
  
  .active-link {
    color: #ff9900; // Цвет текста для активных ссылок
    font-weight: bold; // Можете добавить другие стили по вашему желанию
  }
  
  .nav-logo {
    width: 30px;
    height: 30px;
    // background-color: rgb(26, 25, 25);
    margin-right: 1rem;
  }
  
  
  .inter_active_icon {
    display: flex;
    align-items: center;
  
    &:hover {
      cursor: pointer;
    }
  }
  
  .nav-company_title {
    color: #6F6F6F;
    text-transform: uppercase;
    text-align: center;
  
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 127.523%;
    /* 2.39106rem */
    letter-spacing: 0.19688rem;
    margin: .3rem 0;
  }
  
  .nav-company_title {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.8s 0.2s, transform 0.8s;
    /* Увеличьте значения transition для замедления анимации */
  }
  
  .scrolled .nav-company_title {
    opacity: 0;
    transform: translateY(-100%);
  }
  
  /* Появление текста при наведении курсора */
  .inter_active_icon:hover .nav-company_title {
    opacity: 1;
    transform: translateY(0);
  }
  
  
  .popup-menu-container {}
  
  .pop_link-btn {
    text-align: center;
    width: 100%;
  
  
  }
  
  .pop-up-text {
    font-size: 1.2rem;
    color: #6e6e6e;
    padding: .5rem;
  
    &:hover {
      color: #fff;
    }
  }
  
  .pop_link-btn:hover {
    background-color: #6e6e6e85;
  
  }
  
  
  
  
  .awd {
    // ... остальные стили NavBar
  
    .nav-stroke {
      position: relative;
      background: linear-gradient(90deg, #ff6b6b, #007bff);
      background-size: 0% 100%;
      background-repeat: no-repeat;
      transition: background-size 0.3s ease-in-out;
  
      &:hover {
        background-size: 100% 100%;
      }
  
      ul {
        // ... остальные стили для списка ссылок
      }
    }
  
  
  }
  
  .popup-mainmenu {
    width: 300px;
    right: 20px;
    top:1rem;
    border-radius: 1rem;
  }
  
  .overlay {
    display: none;
    width: 100%;
    height: 100%;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(5px);
    transition: background-color 2.3s ease;
  }
  .overlay.active {
    background-color: rgba(0, 0, 0, 0.5); /* Конечная прозрачность */
  }
  .burger-menu{
    display: block;
  }
  @media screen and (max-width: 600px) {
    .overlay{
      display: block;
    }
    .popup-mainmenu{
      top:0;
    }
    .pop-up-text{
      padding: 1rem 0;
      font-weight: 600;
    }
    .pop_link-btn{
      border-bottom: 2px solid #c2c2c2;
    }
    .NavBar {
      padding-bottom: 2.2rem;
    }
  
    .nav-link-container {
      width: 100%;
      height: .5rem;
      overflow-x: auto;
      /* Добавляем горизонтальную прокрутку */
      white-space: nowrap;
      /* Предотвращаем перенос текста на новую строку */
    }
  
    
    .overlay .active{
      background-color: rgba(0, 0, 0, 0);
    }
  
    .popup-mainmenu {
      border-radius: 0;
      width: 100%;
      right: 0;
  
    }
  
    .nav-bar-buf {
      overflow-x: hidden;
    }
  
    .nav-point {
      display: none;
    }
  
    /* Additional styles for small screens */
  }
  
  
  /* Media query for screens from 601 to 1024 pixels wide (e.g., tablets) */
  @media screen and (min-width: 601px) and (max-width: 1024px) {
    /* Additional styles for medium screens */
  
    .mv1 {
      display: none;
    }
  }
  
  /* Media query for screens 1025 pixels wide and above (e.g., desktops) */
  @media screen and (min-width: 1025px) {
    .mv1 {
      display: none;
    }
  
    /* Additional styles for large screens */
  }
  
  /* Example media query for touch input devices (e.g., tablets and smartphones) */
  @media screen and (hover: none) {
    /* Additional styles for touch input devices */
  }

  @media screen and (max-width:601px){
    .burger-menu{
      display: block;
    }
  }

  @media screen and (max-width:768px) {
    .nav-logo{
      margin: .3rem 0;
    }
    .nav-company_title{
      display: none;
    }
    
  }