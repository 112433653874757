
.Container {
    
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    
    
    &.main{
     height: 100vh;
    }
    &.section-field{
        min-height: 100vh;
        
    }
    &.algmnt{
        
        flex-direction: column;
    }
}



.filter {
    position: relative;
    overflow: hidden;
}

.filter.ton::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.filter.blur::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 2;
}




.filter > * {
    position: relative;
    z-index: 3;
}

.headerCover h2 {
    color: var(--color-white);
    font-size: var(--font-size-large);
    z-index: 3;
    text-align: center;
}

.headerCover p {
    text-transform: uppercase;
    font-weight: 600;
    z-index: 3;
    font-size: var(--font-size-medium);
    color: #00ff55;
    margin: 0 2rem;
    text-align: center;
}
.purch-plan-list{
    display: flex;
    
    width: 80%;
    justify-content: space-between;
    
}

.purch-item{
    display: flex;
    width: 23%;
}

.img-cont-aligm {
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-background-position: center;
    background-position: center;
    -webkit-background-repeat: no-repeat;
    background-repeat: no-repeat;
}

.offers-field {
    margin-bottom: 2rem;
    
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    width: 80%;
    display: flex;
    justify-content: space-between;
    
    flex-wrap: wrap;
    
}

.field-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.container-header{
    margin: 2rem 0;
    width: 80%;
}

.header-space{
    margin: 3rem 0;
}

.separator-stripe{
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background: #1ba94a;
    height: 2rem;
}
.stripe-content{
    color: #fff;
    display: flex;
    a{
        color: #fff;
        border-bottom:1px solid #fff ;
    }
}

.box-shadow{
    p{
        color: #fff;
    }
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #1ba94a;
}

.box-contain{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
}
.simpl_link{
    font-weight: 600;
    border-bottom:1px solid #000 ;
}
.shadow-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
}
.box-item-1{
    width: 40%;
}

.box-item-2{
    border-left: 1px solid #fff;
    width: 55%;
}
.sh-item-title{
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
}

.sh-item-text{
    margin-top: 1rem;
}

.shadow-item-contain{
    
    display: flex;
    flex-direction: column;
}

.shadow-box-img{
    display: flex;
    margin: 2rem;
    height: 150px;
    min-width: 150px;
}

.shadow-btn{
    cursor: pointer;
    border-top: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    p{
        margin: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.3rem;
    }
}

.infograph{
    margin-top: 3rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
}
.infograph-item{
    width: 24%;
    display: flex;
    flex-direction: column;
}
.arrow{
    
    height: 2rem;
    background: #1ba94a;
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
}
.infograph-item-title{
    font-weight: 600;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 1rem;
}
.inf-img-item{
    margin-bottom: 2rem;
    
    width: 150px;
    height: 150px;
    
}

.img-box-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.img-separator{
    width: 100%;
    
    margin-top: 4rem;
}

.separator-text-box{
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
        font-size: 3rem;
        text-transform: uppercase;
        color: #fff;
        font-weight: 600;
    }
}

.segment-border{
    margin: 6rem 0 2rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
}
.segment-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.reverse{
        margin-top: 1rem;
    }
}


.segment-title{
    display: flex;
    width: 100%;
    text-align: left;
    color: #1ba94a;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 2rem;
   
}
.text-content{
    width: 70%;
    display: flex;
    margin-left: 3rem;
    text-align: justify;
    &.reverse{
        margin-left: 0;
        margin-right: 3rem;
    }
    
}

.segment-img{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
}
.segment-image-item{
    width: 32%;
    height: 300px;
    box-shadow: 5px 6px 10px 1px rgba(0, 0, 0, 0.25);
}

.text-contain{
    line-height: 1.5rem;
    font-size: 1rem;
    margin-top: 2rem ;
    
}

.offer-title-contain {
    margin: 3rem 0 2rem 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
.serv_option_item{
    width: 300px;
}
.serv_options{
    flex-wrap: wrap;
    margin-top: 2rem;
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.linker{
    text-transform: uppercase;
    border: none;
    color: #fff;
    background-color: #1ba94a;
    p{
        padding-bottom: .3rem;
        border-bottom: 1px solid #fff ;
    }
}


.filed-grid{
    margin-top:3rem ;
    display: grid;
    width: 80%;
    
    justify-content: center;
    justify-items: center;
    
    gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(clamp(350px,20%,450px), 1fr));
}
.grid-item{
    width: 350px;
}

.space{
    width: 100%;
    height: 3rem;
}
@media screen and (max-width: 1820px) {
    .filed-grid{
        width: 60%;
        grid-template-columns: repeat(auto-fill, minmax(clamp(420px,20%,450px), 1fr));
    }
    .grid-item{
        width: 400px;
    }
}
@media screen and (max-width: 1440px) {
    .filed-grid{
        width: 70%;
        
    }
    .grid-item{
        margin: 0 1rem;
    }
    .infograph-item-title p{
        font-size: 1rem;
    }
    .purch-plan-list{
        justify-content: center;
    }
    .purch-plan-list{
        width: 90%;
        
        flex-wrap: wrap;
    }
    .purch-item{
        
        width: 300px;
    }
    .box-contain{
        width: 90%;
    }
    .infograph{
        width: 90%;
    }
    .container-header{
        width: 90%;
    }
    .offers-field{
        width: 90%;
    }
    .segment-border {
        width: 90%;
    }
}

@media screen and (max-width: 1024px) {
    .filed-grid{
        width: 100%;
        align-items: center;
        flex-direction: column;
    }
    
    .offers-field{
        flex-direction: column;
        width: 100%;
        align-items: center;
        
    }
    .box-contain{
        width: 100%;
        align-items: normal;
        justify-content: center;
    }
    .shadow-item{
        flex-direction: column;
        justify-content: start;
        align-items: center;
        text-align: center;
    }
    .box-item-2{
        padding-left: 1rem;
    }
   
    
}

@media screen and (max-width: 768px) {
    .serv_options{
        justify-content: center;
    }
    .separator-stripe{
        font-size: .8rem;
    }
    
    .separator-text-box{
        min-height: 100px;
        text-align: center;
    }
    .separator-text-box p{
        font-size: 1rem;
        
    }
    .infograph-item-title p{
        font-size: .9rem;
    }
    .shadow-btn p {
        font-size: 1rem;
        margin: .5rem;
    }
    .shadow-box-img {
        margin: 0 auto;
    }
    .arrow{
        height: 1rem;
    }
    .inf-img-item{
        margin-bottom: 1rem;
        width: 100px;
        height: 100px;
    }
    .container-header{
        margin: 1rem 0;
    }
    .sh-item-title p {
        font-size: 1rem;
    }
    .header-space{
        margin: .5rem 0;
    }
    .headerCover h2{
        
        font-size: 2rem;
    }
    .headerCover p {
        margin-top: 2rem;
        font-size: .9rem;
    }
    
    .segment-field {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        
    }
    .segment-border{
        margin: 2rem;
        width: 100%;
    }
    .segment-img{
        overflow-x: hidden;
    }
    .segment-image-item{
        
        height: 250px;
        width: 34%;
    }

    
    
    .sh-item-text{
        font-size: .9rem;
    }
    
    .segment-title{
        width: auto;
        font-size: 1rem;
        text-align: center;
    }
    .text-contain{
        margin-top: 1rem;
        width: 90%;
        font-size: .9rem;
        display: flex;
    }.text-content{
        width: 90%;
        margin-left: 0;
        font-size: .9rem;
        text-align: justify;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .offers-field{
        margin-bottom: 0;
    }
    .offer-title-contain{
        margin: 1rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 568px) {
    
    .box-contain{
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .box-item-2{
        width: 80%;
        border-left:0 ;
        border-top: 2px solid #fff;
    }
    .box-item-1{
        width: 80%;
    }
    .shadow-btn p{
        margin: 2rem;
    }
    .arrow{
        clip-path: polygon(0% 0%,100% 0%, 100% 100%, 0% 100%);
    }
    .inf-img-item{
        padding: 0;
        height: 70px;
        width: 70px;
    }
    .infograph-item-title{
        text-align: center;
        p{
            font-size: .7rem;
        }
    }
}

@media screen and (max-width: 426px) {
    
    .grid-item{
        width: 300px;
    }
}

@media screen and (max-width: 361px) {
    
}


@media screen and (min-width: 1024px) and (max-width: 1360px) {
    .purch-plan-list{
        width: 60%;
    }
    
  }


  @media screen and (min-width: 1024px) and (max-width: 1530px) {
    .serv_options{
        
        width: 70%;
    }}
    