.offer-details {
    width: 40%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    form {
        display: flex;
        flex-direction: column;

        label {
            font-size: 14px;
            margin-bottom: 10px;

            input[type="email"] {
                margin-left: 1rem;
                padding: 10px 0;
                margin-top: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 14px;
            }

            input[type="file"] {
                margin-top: 5px;
            }
        }

        .captcha {
            margin-bottom: 20px;

            label {
                display: flex;
                align-items: center;
                gap: 10px;

                input[type="checkbox"] {
                    transform: scale(1.2);
                }
            }
        }

        button {
            padding: 10px;
            background-color: #1ba94a;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            transition: 0.3s ease;

            &:hover {
                background-color: #1ba94a;
            }
        }
    }
}
.uploader{
    margin-bottom:1rem;
}

.selector_num{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trees-input{
    padding: .2rem;
    margin-bottom: .5rem;
}

.offer-selection {
    width: 60%;
    display: flex;
    justify-content: center;

   

    .offer-package {
        overflow-x: hidden;
        margin: 0 1rem;
        width: 300px;
        text-align: center;
        border: 2px solid transparent;
        border-color: #cfcfcf;
        
        border-radius: 10px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
            border-color: #1ba94a;
            
        }

        &.selected {
            border-color: #1ba94a;
            background-color: #f4fff4;
        }

        img {
            width: 100%;
            height: auto;
            
            margin-bottom: 10px;
        }

       
    }
}

.drop-offer {

    display: flex;
    flex-direction: column;
    align-items: center;

    .drop-container {
        margin: 0 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
    }

    width: 90%;
    margin-bottom: 2rem;
    
}

.file-upload-container {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #4CAF50; 
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9; 
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
.file-upload-status{
    margin: 1rem 0;
}
  .file-upload-input {
    display: none;
  }
  

  .file-upload-label {
    color: #4CAF50; 
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  

.drop-text{
    margin-bottom: 1rem;
}

.suggestion{
    font-weight: 600;
}

.drop-offer-bundle{
    background-color: #1ba94a;
    color: #fff;
    display: flex;
    justify-content: center;
    width: 100%;
    p{
        margin: .6rem;
    }
    &:hover{
        cursor: pointer;
    }
}

.drop-format{
    margin: 2rem 0;
    width: 90%;
}

.drop-container-title{
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 1rem;
}

.separator{
    margin: 1rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;

}
.line{
   width: 45%;
    height: 2px;
    background: #333;
}

.updatable{
    text-transform: uppercase;
    color: #1ba94a;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
}

@media screen and (max-width:768px) {
    .drop-container{
        flex-direction: column;
    }
    .offer-selection{
        width: 100%;
        margin-bottom: .5rem;
    }
    .offer-details{
        
        width: 100%;
    }
    .drop-text{
        font-size: .8rem    ;
    }
    .drop-container-title{
        font-size: 1rem;
    }
    .updatable{
        font-size: 1rem;
    }
    .drop-offer-bundle{
        p{
            font-size: .8rem;
        }
    }
    .offer-selection .offer-package{
        margin: .2rem;
    }
    .drop-format{
        width: 80%;
        margin: .5rem 0;
    }
    .drop-offer{
        width: 95%;
    }
    .inner-format{
        margin: 0.2rem;
    }
}