.container-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    width: 100%;
}

.container-box-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 1rem;

}

.container-box-text {
    margin-top: 4rem;
    width: 90%;
    text-align: center;
    font-size: 1rem;

    p {
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .container-box{
        margin-top: 0;
    }
    .container-box-title{
        font-size: 1rem;
    }
    .container-box-text{
        font-size: .9rem;
    }
    .container-box-text{
        margin-top: 2rem;
    }
}