.projects-root {
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.stats-container {
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;

}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-list {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.project-item {
    margin: 0 1rem;
    display: flex;
}

.popup-content {
    overflow-x: hidden;
    background: white;
    display: flex;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    position: relative;


}

.pop-map {
    width: 50%
}

.pop-container {
    padding: 1rem;
    width: 50%;
}

.pc-title {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}


.project-container {
    display: flex;
    width: 300px;
    height: 300px;
    flex-wrap: wrap;
}


.hex {

    width: 100%;
    height: 100%;
    background: #ffffff;
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hex1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1ba94a;
    width: 290px;
    height: 290px;
    background-size: cover;
    background-repeat: no-repeat;
    -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
}

.card-title {
    color: #fff;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
}

.stats-item {
    background: #fff;
    margin: 0 1rem;
    width: 150px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    box-shadow: 5px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.stats-number {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.pc-subtitle {
    margin-top: 1rem;
    margin-bottom: .3rem;
    font-weight: 600;
}

.popup-btn {
    margin-top: 1rem;
    color: #fff;
    padding: .5rem;
    border: none;
    background-color: #1ba94a;
    width: 100%;
}


@media screen and (max-width: 1024px) {
    .project-container {
        width: 180px;
        height: 180px;
    }

    .hex1 {
        width: 170px;
        height: 170px;
    }

    .card-title {
        font-size: 1.2rem;
    }

    .stats-item {
        width: 120px;
        height: 170px;
    }

    .stats-number {
        font-size: 1rem;
    }

    .stats-name {
        font-size: .9rem;
    }

}


@media screen and (max-width: 568px) {
    .projects-root{
        min-height: calc(100vh - 2.2rem - 70px);
    }
    .project-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project-container {
        width: 380px;
        height: 80px;
        clip-path: none;
    }
    .hex{
        clip-path: polygon(10% 5%, 90% 5%, 100% 50%, 90% 95%, 10% 95%, 0% 50%);
    }
    .hex1 {
        width: 365px;
        height: 70px;
        clip-path: polygon(10% 5%, 90% 5%, 100% 50%, 90% 95%, 10% 95%, 0% 50%);
    }
    .popup-content{
        flex-direction: column;
    }
    .pop-container{
        width: 90%;
       
    }
    .pop-map{
        height: 150px;
        width: 100%;
    }
    .pc-subtitle{
        font-size: .8rem;
        margin-top: .5rem;
    }
    .pc-text{
        font-size: .8rem;
    }
}