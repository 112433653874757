.impact-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.container-imp{
    width: 80%;
}
.check-list {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
}
.check-item {
    margin-top: 1rem;
    width: 45%;
}

.bullet {
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #2b2b2b;
}

.stripe {
    margin: 0 6px;
    width: 3px;
    background-color: #2b2b2b;
}

.marks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.shape {
    margin-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.mark-title {
    font-weight: 600;
    font-size: 1.3rem;
    margin-left: 3rem;
}

.shape-text {
    margin: 1rem 0;
    margin-left: 3rem;
    min-height: 50px;
}

.impc-cover{
    width: 100%;
    height: 500px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.section-title{
    background:#1ba94a ;
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p{
        color: #fff;
        font-weight: 600;
        font-size: 1.5rem;
        text-transform: uppercase;
        margin: 1rem;
    }
}

.imp-title{
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: 1.3rem;
}
@media screen and (max-width:1440px) {
    .section-title{
        margin-bottom: .5rem;
    }
    .container-imp{ width: 90%;}
   
    .section-title{
        
        p{
            font-size: 1.4rem;
            
        }
    }
}

@media screen and (max-width:1024px) {
    .container-imp{ width: 95%;}
   
    .section-title{
        
        p{
            font-size: 1.2rem;
            margin: .5rem;
        }
    }
}

@media screen and (max-width:768px) {
    .section-title{
        p{
            font-size: 1rem;
        }
        
    }
    .shape-text{
        margin-top:.2rem;
        margin-bottom:.2rem;
        font-size: .8rem;
    }
    .mark-title{
        font-size: 1rem;
    }
    .imp-title{
        font-size: 1rem;
    }
    .check-list{
        justify-content: center;
    }
    .check-item{
        width: 95%;
    }
}